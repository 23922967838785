import {inject } from 'aurelia-framework';
import {HeaderService} from '../../shared/services/headerservice';
import {LoginService} from '../../shared/services/loginservice';
import {Router} from 'aurelia-router';
import {JwtService} from '../services/jwtservice';
import {config} from '../../shared/services/config';
import $ from 'jquery';
import {SessionTimeoutService} from "../services/sessiontimeoutservice";
import {SharedState} from "../state/sharedstate";

@inject(LoginService,Router,HeaderService,JwtService,SessionTimeoutService,SharedState,Element)
export class HeaderLayout{
  navigateb(route) {
    if (route) {
      this.router.navigateToRoute(route);
  }
}

disableRightClick(event) {
    event.preventDefault();
}
  routerView = "main-content";
    dropdownElement;

    constructor(loginService,router,headerservice,jwtService,sessionTimeoutService,sharedState,element){
    this.loginService= loginService;
    this.router = router;
    this.headerservice = headerservice; 
    this.jwtService = jwtService;
        this.sessionTimeoutService = sessionTimeoutService;
    this.dropdownElement = element.querySelector('#dropdown-menu');
        window.addEventListener('resize', ()=>{handleResize1});
         function handleResize1() {
             return window.customWidth = window.innerWidth;
        }
        this.sharedState = sharedState;
    }

  filter() {
    const body = document.body;
    body.classList.toggle('open-sidebar');
    body.classList.remove('open-search');
  }

  searchopen() {
    const body = document.body;
    body.classList.toggle('open-search');
    body.classList.remove('open-sidebar');
  }

  filter_icons() {
    const body = document.body;
    body.classList.toggle('open-filter-icons');
  }

  attached() {
    this.service = this.jwtService.getService();
        window.addEventListener('resize', windowSize);

      function windowSize (){
        return  window.customWidth = window.innerWidth;
      }

      document.querySelector('router-view').classList.add(this.routerView);
    this.empId = this.jwtService.getEmployeeId();
    setTimeout(()=>{
        this.headerservice.dynamicHeader(this.empId)
            .then(data => {
                this.employeedetails = data;
                this.dynamicLogo=config.img_url + data.LoginLogo;
                this.userPhoto=config.img_url + data.PhotoPath;
                this.designation = data.Designation;
                this.departmentId = data.DepartmentId;
            });
            
    },100);
      $(document).on("click", function(event){
          let $trigger = $(".user-picture");
          if($trigger !== event.target && !$trigger.has(event.target).length){
              $("#dropdown-menu").removeClass('dropdown-menu');
          }
      });

      window.addEventListener('mousemove', this.handleUserActivity);
      window.addEventListener('keydown', this.handleUserActivity);
  }

  detached() {
        // Remove event listeners when the component is detached
        window.removeEventListener('mousemove', this.handleUserActivity);
        window.removeEventListener('keydown', this.handleUserActivity);
    }
    handleUserActivity = () => {
        // Reset the session timeout timer on user activity
        this.sessionTimeoutService.resetTimer();
    };

  toggleProfile(){
    let toggleProfile =document.querySelector('#dropdown-menu');
    document.querySelector('#dropdown-menu').classList.toggle("dropdown-menu");
  }

  RefreshProfileImage(data){
    this.userPhotoElement = document.querySelector('.rounded-full.dropdown.inline-block');
    this.userPhotoElement.src = data;
  }

  navigate(support) {
    if (
      (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
      (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
    ) {
      setTimeout(function () {
        window.location.reload();
      }, 500);
    }
    this.router.navigateToRoute(support);
    let subActiveElements = document.querySelectorAll('.sub-active');
    subActiveElements.forEach(element => {
      element.classList.remove("sub-active");
    });

    let activeLinkElements = document.querySelectorAll('.active-link');
    activeLinkElements.forEach(element => {
      element.classList.remove("active-link");
    });

    const body = document.body;
    body.classList.remove('open-sidebar');
  }

  handleClick(){

  window.location.reload();

} 
}
