import environment from '../config/environment.json';
import {PLATFORM} from 'aurelia-pal';

export function configure(aurelia) {
  aurelia.use
    .standardConfiguration() 
    .plugin(PLATFORM.moduleName('aurelia-validation'))  
    .feature(PLATFORM.moduleName('shared/index'))
    .feature(PLATFORM.moduleName('resources/index'))
    .plugin(PLATFORM.moduleName('au-table'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'));

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));    
  }

  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
